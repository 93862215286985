import {lazy} from "react";
import LoginFunc from './../utils/updateDataFunc';
import Logo from "./../assets/images/logologin.png";
const PrivateRoute = lazy(() => import('./../utils/privateRoute'));
const StoreList = lazy(() => import('./../components/store/storeList'));
const StoreCreator = lazy(() => import('./../components/store/createStore'));
const StoreOpenHours = lazy(() => import('./../components/store/openHours'));
const StoreDelivery = lazy(() => import('./../components/store/delivery'));
const imageData = {
  "logo": Logo,
  "footer_logo": Logo
};
const route = [
  {path: '/store', element: <PrivateRoute><StoreList /></PrivateRoute>},
  {path: '/store/creator', element: <PrivateRoute><StoreCreator loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>},
  {path: '/store/creator/:s_id', element: <PrivateRoute><StoreCreator loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>},
  {path: '/store/open/:s_id', element: <PrivateRoute><StoreOpenHours loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>},
  {path: '/store/delivery/:s_id', element: <PrivateRoute><StoreDelivery loginFun={LoginFunc} imageData={imageData} /></PrivateRoute>}
]
export default route;