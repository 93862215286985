import { API_URL, API_URL_B, API_KEY } from './../config/constant';
import axios from 'axios';
const addDataIntoCache = (cacheName, url, response) => {
    const data = new Response(JSON.stringify(response));
    if ('caches' in window) {
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
  };
const loginFun = (d, statusPercentage) => {
  let formData ={
    "userID": d,
    "status": statusPercentage
  }
  if(parseInt(localStorage.getItem("status")) <= parseInt(statusPercentage) || statusPercentage === 'Completed'){
    localStorage.setItem("status", statusPercentage)
    axios.post(API_URL_B+'updateUserStatus?code=NUHKuyJSgqnjvr2ZpsVtLCtLKepp0aib59_kR9tYaHmcAzFugWwJbw==', formData).then(res => {
      axios.post(API_URL+'get-user-data?code='+API_KEY, {"id":d}).then((res)=>{
        if(res.data.status === 200){
          let resData = {
            "status": res.data.data?.status,
            "completePercentage": res.data.data?.completePercentage,
            "AccountMenu": res.data.data?.AccountMenu,
            "DashboardMenu": res.data.data?.DashboardMenu,
            "BOimagesMenu": res.data.data?.BOimagesMenu,
            "FullfillmentCenterMenu": res.data.data?.FullfillmentCenterMenu,
            "StoresMenu": res.data.data?.StoresMenu,
            "RetailersMenu": res.data.data?.RetailersMenu,
            "ProductsMenu": res.data.data?.ProductsMenu,
            "BrandsMenu": res.data.data?.BrandsMenu,
            "PromotersMenu": res.data.data?.PromotersMenu,
            "companyName": (typeof res.data.data?.Companies != 'undefined')?res.data.data?.Companies[0]?.companyName:"",
            "companyLogo":res.data.data?.companyDetail?.logoURL,
            "billingPlan":res.data.data?.companyDetail?.billingPlan,
            "BillingMenu":res.data.data?.BillingMenu
          }
          localStorage.setItem("__data",JSON.stringify(resData))
          addDataIntoCache("MyCache", window.location.origin, res.data.data)
        }
      });
    });
  }else{
    axios.post(API_URL+'get-user-data?code='+API_KEY, {"id":d}).then((res)=>{
      if(res.data.status === 200){
        let resData = {
          "status": res.data.data?.status,
          "completePercentage": res.data.data?.completePercentage,
          "AccountMenu": res.data.data?.AccountMenu,
          "DashboardMenu": res.data.data?.DashboardMenu,
          "BOimagesMenu": res.data.data?.BOimagesMenu,
          "FullfillmentCenterMenu": res.data.data?.FullfillmentCenterMenu,
          "StoresMenu": res.data.data?.StoresMenu,
          "RetailersMenu": res.data.data?.RetailersMenu,
          "ProductsMenu": res.data.data?.ProductsMenu,
          "BrandsMenu": res.data.data?.BrandsMenu,
          "PromotersMenu": res.data.data?.PromotersMenu,
          "companyName": (typeof res.data.data?.Companies != 'undefined')?res.data.data?.Companies[0]?.companyName:"",
          "companyLogo":res.data.data?.companyDetail?.logoURL,
          "BillingMenu":res.data.data?.BillingMenu,
          "billingPlan":res.data.data?.companyDetail?.billingPlan
        }
        localStorage.setItem("__data",JSON.stringify(resData))
        addDataIntoCache("MyCache", window.location.origin, res.data.data)
      }
    });
  }
}
export default loginFun;