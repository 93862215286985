const Stats = ({
    currentStep,
    firstStep,
    goToStep,
    lastStep,
    nextStep,
    previousStep,
    totalSteps,
    step,
	buttonEnable,
	lastPage,
	processingAction,
}) => (
    <div className="btnsetarea">
    	<div className="col-md-12 firstbtn">
        {
			(lastPage)?lastStep():""
		}
    	{
    		(step < 6)?(step < 5)?<button className='btn btn-default btn-block' onClick={() => goToStep(5)}>SKIP DETAILS</button>:<><button  className={(processingAction)?'btn btn-success btn-block disabledbtn':"btn btn-success btn-block"}  disabled={(processingAction)?"disabled":""} onClick={currentStep}  >CANCEL</button><button className='btn btn-default btn-block vivsible'>INVISIBLE</button></>:""
    	}
		{
			(step === 1)?<button className='btn btn-default btn-block vivsible'>INVISIBLE</button>:""
		}
    	{ step > 1 && step < 5 &&
            <button className='btn btn-default btn-block' onClick={previousStep}>PREVIOUS</button>
        }
        { (step > 5)?"":(step === 5)?<button className={(processingAction)?"btn btn-success btn-block disabledbtn":(buttonEnable)?"btn btn-success btn-block":'btn btn-success btn-block disabledbtn'} disabled={(processingAction)?"disabled":(buttonEnable)?"":"disabled"} onClick={() => {nextStep()}}>PRE-ENROLL</button>:(step === 1)?<button className="btn btn-primary btn-block" onClick={nextStep}>VIEW STEP BY STEP</button>:(step < totalSteps) ?
        	(step === 4)?<button className="btn btn-primary btn-block" onClick={nextStep}>START ENROLLMENT</button>:<button className="btn btn-primary btn-block" onClick={nextStep}>NEXT</button>:
            <button className='btn btn-success btn-block' onClick={nextStep}>Finish</button>
        }
        </div>
        
    </div>
);
export default Stats;