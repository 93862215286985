import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Steps from './steps'
import axios from 'axios';
import {API_URL, API_KEY} from './../../config/constant'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Warning from './../../assets/images/error-icon.png';
import Success from './../../assets/images/icons8-check-64.png';
toast.configure();
const Five = (props) => {
	const [buttonEnable, setButtonEnable] = useState(false)
	const [companyName, setCompanyName] = useState("")
	const [companyError, setCompanyError] = useState(false)
	const [email, setEmail] = useState("")
	const [emailError, setEmailError] = useState(false)
	const [firstName, setFirstName] = useState("")
	const [firstNameError, setFirstNameError] = useState(false)
	const [lastName, setLastName] = useState("")
	const [goToNextPage, setGoToNextPage]  = useState(false)
	const [showLoader, setShowLoader] = useState(false)
	const uiid = useParams()
	const navigate = useNavigate();
	useEffect(() => {
		if(props?.response){
			setEmail(props?.response?.email)
			setFirstName(props?.response?.firstName)
			setLastName(props?.response?.lastName)
		}
	},[props?.response])
	const {retailer} = props.response
	const handleCheckbox = (e) => {
		setButtonEnable(e.target.checked)
	}
	const handleCompany = (e) => {
		setCompanyError(false)
		setCompanyName(e.target.value)
	}
	const handleEmail = (e) => {
		setEmailError(false)
		setEmail(e.target.value)
	}
	const handleFirstName = (e) => {
		setFirstNameError(false)
		setFirstName(e.target.value)
	}
	const handleLastName = (e) => {
		setLastName(e.target.value)
	}
	const validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }
	const handleValidation = () =>{
		let formIsValid = true;
		if(!companyName){
			setCompanyError(true);
			formIsValid = false;
		}
		if(!email){
			setEmailError(true);
			formIsValid = false;
		}else if(!validateEmail(email)){
			setEmailError(true);
			formIsValid = false;
		}
		if(!firstName){
			setFirstNameError(true);
			formIsValid = false;
		}
		return formIsValid;
	}
	const handleCancle = () => {
		navigate("/")
	}
	const handlePreEnroll = () => {
		if(handleValidation()){
			props.loader(true)
			setShowLoader(true)
			let jsonData = {
				companyName: companyName,
				retailBrand: retailer,
				firstName: firstName,
				lastName: lastName,
				email: email,
				invitedID: uiid.id
			}
			axios.post(API_URL+"register-retailer-brand?code="+API_KEY, jsonData).then(res => {
				if(res.data.status === 200){
					setGoToNextPage(true)
					props.companyNameFun(companyName)
					props.emailFun(email)
					props.userIDFun(res.data.data.insertedId)
					toast.success(res.data.message, {
					  	theme: "dark",
					  	icon: ({theme, type}) =>  <img src={Success} alt=""/>
					})}
					else if(res.data.status === 401){
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({theme, type}) =>  <img src={Warning} alt=""/>,
							onOpen: () => navigate("/"),
						  }) 
					}else{
						toast.error(res.data.message, {
							theme: "dark",
							icon: ({theme, type}) =>  <img src={Warning} alt=""/>
						})
					}
				props.loader(false)
			})
		}		
	}
    return (
    	<>
    	<div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizardtext twosections accountsowner">
			            	<h3>Pre-enrollment form</h3>
									            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">			            			
			            			<div className="col-md-5">
			            				<div className="card-body">
			            					<form>
			            						<div className="row">
												   <div className="col-md-12">
			            								<h2 className="formtexttile"><span>Retail Brand:</span> {retailer} </h2>
			            								<div className={(showLoader)?"form-group inActiveborder ftColor":"form-group activeborder"}>
			            									<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Company name</label>
			            									<input type="text" className={(companyError)?"form-control error-input":"form-control"} value={companyName} onChange={handleCompany} />
			            								</div>
			            							</div>
			            						</div>
			            						<div className="row">
			            							<div className="col-md-12">
			            								<div className={(showLoader)?"form-group inActiveborder withicons ftColor":"form-group activeborder withicons"}>
			            									<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Account owner's email</label>
			            									<div className="addafterbefore">
			            										<input type="text" className={(emailError)?"form-control error-input":"form-control"} value={email} onChange={handleEmail} />
																<span className="verfiefd-text">Verified</span>
			            									</div>
			            								</div>
			            							</div>
			            						</div>
			            						<div className="row">
			            							<div className="col-md-6">
			            								<div className={(showLoader)?"form-group inActiveborder ftColor":"form-group activeborder"}>
			            									<label htmlFor="tobrand"><i className="fas fa-info-circle" aria-hidden="true"></i> Account owner</label>
			            									<input type="text" className={(firstNameError)?"form-control error-input":"form-control"} value={firstName} onChange={handleFirstName} />
			            									<span className="small-label">First name</span>
			            								</div>
			            							</div>
			            							<div className="col-md-6">
			            								<div className={(showLoader)?"ftColor form-group":"form-group"}>
			            								<label htmlFor="tobrand" className="hiditfornow">&nbsp;</label>
			            									<input type="text" className={(props.lastNameError)?"form-control error-input":"form-control"} value={lastName} onChange={handleLastName} />
			            									<span className="small-label">Last name</span>
			            								</div>
			            							</div>
			            						</div>
			            						<div className="row">
			            							<div className="col-md-12 bottomlevel">
			            								<span className="formcautions"><ul><li><span className="makesqaure mandatorythis"></span>mandatory fields</li><li><span className="makesqaure lockedthis"></span>locked fields</li></ul></span>
			            							</div>
			            						</div>
			            					</form>
			            				</div>
			            				
			            			</div>
			            			<div className="col-md-7">
			            				<div className="rightparatext">
			            					<p>A <span className="whiteandbg">BO</span>stage<span className="stage-orange">R</span> Brand account is created with an <span className="whiteandbg">Account Owner</span> profile. The Account Owner has full decision power over any other user of the account.
			            					</p>

											<p>If you are not the right person to be registered as the Account Owner, please replace your email and name with the correct person's data. Credentials to proceed with the enrollment process will be sent to the provided email address.
											</p>

											<p className="pre-font">Later you'll be asked to create an Account Admin profile. The Account Admin will have its own credentials.
											</p>

											<p className="checkkbox pre-font"><label className="container-checkbox"><input type="checkbox" name="select-row" value="" onChange={handleCheckbox}/><span className="checkmark"></span></label> I have read and I agree with the <u>Terms and Conditions.</u></p>
			            				</div>
			            			</div>
			            		</div>
			            	</div>

			            	     <Steps step={5} {...props} nextStep={handlePreEnroll} currentStep={handleCancle} buttonEnable={buttonEnable} lastPage={goToNextPage} /> 
		            	</div>
		            </div>
	            </div>
            </div>
        </div>
        
		   
        </>
     );
};
export default Five;