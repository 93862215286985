import {lazy} from "react";
import LoginFunc from './../utils/updateDataFunc';
import Logo from "./../assets/images/logologin.png";
import FooterLogo from "./../assets/images/footer-logologin.png";
const PrivateRoute = lazy(() => import('./../utils/privateRoute'));
const BOImage = lazy(() => import('./../components/boimages/boImageList'));
const BOImageCreator = lazy(() => import('./../components/boimages/creator'));
const BOImageCanvas = lazy(() => import('./../components/boimages/canvas'));
const BOImageTarget = lazy(() => import('./../components/boimages/targets'))
const PreviewImage = lazy(()=> import('./../components/boimages/previewImage'));
const RetailBoimagesList = lazy(()=> import ('./../components/boimages/retailBoimagesList'));

const imageData = {
    "logo": Logo,
    "footer_logo": FooterLogo
  };
  
const route = [
    {path: '/boimages', element: <PrivateRoute><BOImage loginFun={LoginFunc} imageData={imageData}/></PrivateRoute>},
    {path: '/boimages/preview/:BOimageIdData', element: <PrivateRoute><PreviewImage loginFun={LoginFunc} imageData={imageData}/></PrivateRoute>},
    {path: '/boimages/creator', element: <PrivateRoute><BOImageCreator loginFun={LoginFunc} imageData={imageData}/></PrivateRoute>},
    {path: '/boimages/creator/:BOimageIdData', element: <PrivateRoute><BOImageCreator loginFun={LoginFunc} imageData={imageData}/></PrivateRoute>},
    {path: '/boimages/canvas', element: <PrivateRoute><BOImageCanvas loginFun={LoginFunc} imageData={imageData}/></PrivateRoute>},
    {path: '/boimages/canvas/:BOimageIdData', element: <PrivateRoute><BOImageCanvas loginFun={LoginFunc} imageData={imageData}/></PrivateRoute>},
    {path: '/boimages/target/:BOimageIdData', element: <PrivateRoute><BOImageTarget loginFun={LoginFunc} imageData={imageData}/></PrivateRoute>},
    {path: '/retail-list', element: <PrivateRoute><RetailBoimagesList loginFun={LoginFunc} imageData={imageData}/></PrivateRoute>},

];
export default route;