import React, {useState, useEffect} from "react";
import { Link, NavLink,useNavigate } from "react-router-dom";
import MenuIsOn from './../../assets/images/dashboardicon.png';
import MenuIsOff from './../../assets/images/dashoboardoff.png';
import BOimageIsOn from './../../assets/images/boimageson.png';
import BOimageIsOff from './../../assets/images/boimagesoff.png';
import FulfillmentOn from './../../assets/images/fullfillmenton.png';
import FulfillmentOff from './../../assets/images/fullfillmentoff.png';
import StoresOn from './../../assets/images/storeson.png';
import StoresOff from './../../assets/images/storesoff.png';
import BrandOn from './../../assets/images/brandon.png';
import BrandOff from './../../assets/images/brandoff.png';
import RetailersOn from './../../assets/images/retailerson.png';
import RetailersOff from './../../assets/images/retailersonoff.png';
import ProductisOn from './../../assets/images/productison.png';
import ProductisOff from './../../assets/images/productisoff.png';
import AccountOn from './../../assets/images/accounton.png';
import AccountOff from './../../assets/images/accountoff.png';
import BillingOn from './../../assets/images/billingon.png';
import BillingOff from './../../assets/images/Billingoff.png';
import SettingOn from './../../assets/images/settingon.png';
import SupportOn from './../../assets/images/supporton.png';
import PromoterOn from './../../assets/images/promoters.png';
import PromoterOff from './../../assets/images/promotersoff.png';
import MenuWarning from './../../assets/images/menu-warning.png';
import CautionMenu from './../../assets/images/caution-menu.png';
import Tooltip from "@mui/material/Tooltip";
import axios from 'axios';
import { API_URL, API_KEY } from './../../config/constant'
function BrandAside(props) {  
	const [cacheData, setCacheData] = useState("");
	const [storeID, setStoreID] = useState(null);
	const [localData, setLocalData] = useState("")
	const [companyName, setCompanyName] = useState("")
	const [companyLogo, setCompanyLogo] = useState("")
	const [isInEnrolled, setIsInEnrolled] = useState(false)
	const [status, setStatus] = useState("")
	const [completePercentage, setCompletePercentage] = useState("")
	const [billingPlan, setBillingPlan] = useState("")
	const[group, setGroup] = useState("");
	const [groupID,setGroupID] =useState("");
	let navigate = useNavigate();

	// const [activeStore, setActiveStore] = useState(0);
	const [activeFFC, setActiveFFC] = useState(0);
	// useEffect(() => {
	// 	 if(localStorage.getItem("brandID")){
	// 		getActiveStoreCount();
	// 	 }
	// },[localStorage.getItem("brandID"),localStorage.getItem("totalStore")])
	// useEffect(() => {
	//     let interval = setInterval(getActiveStoreCount, 2000);
	//     return () => clearInterval(interval);
	// },[]);
	useEffect(() => {
		if(localData){
			setCompanyName(localData?.companyName)
			setCompanyLogo(localData?.companyLogo)
			setIsInEnrolled(JSON.parse(localStorage.getItem("isInEnrolled")))
			setStatus(localStorage.getItem("status"));
			setCompletePercentage(localData?.completePercentage)
			setBillingPlan(localData?.billingPlan)
		}
	},[localData])
	useEffect(()=>{
		getActiveStoreCount();
	},[]);
	const getActiveStoreCount = () => {
		let b_id= "";
    if((localStorage.getItem("brandID")!="undefined")){
      b_id = localStorage.getItem("brandID")
    }
		axios.post(API_URL+"get-store-ffc-count?code="+API_KEY,{brandID: b_id}).then(res => {
			//axios.post("http://localhost:7071/api/get-store-ffc-count",{brandID: b_id}).then(res => {
			if(res.data.status === 200){
				// setActiveStore(res.data?.data?.totalActiveStore)
				localStorage.setItem("totalStore",res.data?.data?.totalActiveStore);
				localStorage.setItem("ActiveFFC",(res.data?.data?.totalFFCStore))
			}
		})
	}
	const checkUserLoggedIn = () => {
		getSingleCacheData("MyCache", window.location.origin)
	    const auth = localStorage.getItem('token');
	    if(!auth){
	    	navigate('/');
	    }else{
	    	setLocalData(JSON.parse(localStorage.getItem("__data")))
	    }
	}
	
	useEffect(() => {
		if(cacheData?.companyDetail?.storeIDs){
			setStoreID(cacheData?.companyDetail?.storeIDs.length)
		}
	},[cacheData])
	useEffect(() => {
	    let interval = setInterval(checkUserLoggedIn, 2000);
	    return () => clearInterval(interval);
	});
	const onMouseOver = (e) => {
		if(e.currentTarget.getElementsByTagName("a")[0].classList.contains('active')){
			e.currentTarget.classList.remove('remove-uper-border')
			e.currentTarget.classList.remove('remove-bottom-border')
		}
		if(e.currentTarget.nextSibling){
			if(e.currentTarget.nextSibling.getElementsByTagName("a")[0].classList.contains('active')){
				e.currentTarget.nextSibling.classList.add('remove-uper-border')
			}else{
				let liTag = document.getElementById('__navbar').getElementsByTagName("li");
				var elemsLenght = liTag.length;
				for (var i = 0; i < elemsLenght; ++i) {
					if (liTag[i].classList.contains('remove-uper-border'))
					{
					    liTag[i].classList.remove('remove-uper-border')
					}
				}
			}
		}
		if(e.currentTarget.previousSibling){
			if(e.currentTarget.previousSibling.getElementsByTagName("a")[0].classList.contains('active')){
				e.currentTarget.previousSibling.classList.add('remove-bottom-border')
			}else{
				let liTag = document.getElementById('__navbar').getElementsByTagName("li");
				var elemsLen = liTag.length;
				for (var j = 0; j < elemsLen; ++j) {
					if (liTag[j].classList.contains('remove-bottom-border'))
					{
					    liTag[j].classList.remove('remove-bottom-border')
					}
				}
			}
		}
		
	}
	const onMouseLeave = (e) => {
		let liTag = document.getElementById('__navbar').getElementsByTagName("li");
		var elemsLenght = liTag.length;
		for (var i = 0; i < elemsLenght; ++i) {
			if (liTag[i].classList.contains('remove-bottom-border') || liTag[i].classList.contains('remove-uper-border'))
			{
			    liTag[i].classList.remove('remove-bottom-border')
			    liTag[i].classList.remove('remove-uper-border')
			}
		}
	}


     
	 const BrandNavigate = () =>{
		 (!isInEnrolled)?navigate("/brand/list"):navigate("/brand/creator");
	 }

	 const BOimagesNavigate = () =>{
		// (!isInEnrolled)?navigate("/boimages/creator/"+localStorage.getItem("boimageID")):navigate("/boimages/creator");
		(!isInEnrolled)?navigate("/boimages"):navigate("#");
	
	}

	 const handleProductNavigation = (e) => {
	 	e.preventDefault()
	 	if(!isInEnrolled){
	 		navigate("/products")
	 	}else{
	 		navigate('#')
	 	}
	 }
	const handleStore = (e) => {
	 	e.preventDefault()
	 	if(isInEnrolled){
	 		navigate('/store/creator')
	 	}else{
	 		navigate('/store')
	 	}
	}
	const getSingleCacheData = async (cacheName, url) => {
	    if (typeof caches === 'undefined') return false;
	    const cacheStorage = await caches.open(cacheName);
	    const cachedResponse = await cacheStorage.match(url);
	    if (!cachedResponse || !cachedResponse.ok) {
	      	setCacheData('Fetched failed!')
	    }
	    return cachedResponse.json().then((item) => {
	      	setCacheData(item)
	    });
	};
	const handleAccountMenu = (e) => {
	 	e.preventDefault();
	 	(!isInEnrolled)?navigate("/account/company/list"):navigate("/account/company/create");
	}
	const clickImage = () =>{
              
		if(localStorage.getItem('status') >= 10 || localStorage.getItem('status') =='Completed'){ 
			navigate("/account/company/logo");
		} 
	}
	
	return(
		<>
			<aside className="main-sidebar">
				<div className="user-profile-height stager">
				  <div className={(companyLogo)?"custom-logo":"custom-logo withoutlogo"}>
				  <div className={(companyLogo)?"logoname logoimg":"logoname"} onClick={clickImage}>
				  {
					 (companyLogo)?<><img alt="" className="leftmainlogo" src={companyLogo} />{(localStorage.getItem('status')==100 || localStorage.getItem('status')=='Completed')?<span className="btn btn-success editComp saveIt" onClick={clickImage}><i className="fa-thin fa-edit saveIt"></i></span>:""}</>:(<><span className="maincompany">Company Logo</span><small>Max 200x90px 100kb</small></>)
				  	//(companyLogo)?<img alt="" className="leftmainlogo" src={companyLogo} />:(<><span className="maincompany">Company Logo</span><small>Max 200x90px 100kb</small></>)
				  }
			        
			      </div>
			      <div className="itemsas ">{companyName} </div>
			    </div>
			    <div className="itemscenter">
			      <ul>
				    <li>Current status: <span className={ (!isInEnrolled && status !== 'Completed')?"brandgreens":(status === 'Completed')?"blue":"orng"}>{(completePercentage === 'Completed')?'Active':completePercentage}</span> 
				    </li>
			        <li className="activated">Billing plan: <span className={(billingPlan)?"blue":"orng"}>{(billingPlan)?billingPlan:"-"}</span> 
			        {
				    	(status === 'Completed')?<span className={"checktext"}>Change</span>:""
				    }
			        </li>
			        {/* <li className="notactivated">Group plan: <span className={(status != 'Completed')?"orng":"blue"}>{(localStorage.getItem("GroupPlan"))?localStorage.getItem("GroupPlan"):"-"}</span></li> */}
					<li className="activated">Group plan: <span className={(status != 'Completed')?"orng":"blue"}>{((localStorage.getItem("GroupPlan") == "undefined")|| (localStorage.getItem("GroupPlan") == "") || (!localStorage.getItem("GroupPlan")))?"-":localStorage.getItem("GroupPlan")}</span></li>
			        <li>Active stores: <span className={((JSON.parse(localStorage.getItem("totalStore"))) > 0 && (status != 'Completed'))?"orng":((JSON.parse(localStorage.getItem("totalStore"))) > 0 && (status === 'Completed'))?"blue":"orng"}>{(JSON.parse(localStorage.getItem("totalStore")))}</span> 
			        {
				    	(status === 'Completed')?<span className={"checktext"}>Check</span>:""
				    }
			        </li>
			        <li>Active FFcenters: <span className={(JSON.parse(localStorage.getItem("ActiveFFC")) > 0 && (status != 'Completed'))?"orng":(JSON.parse(localStorage.getItem("ActiveFFC")) > 0 && (status === 'Completed'))?"blue":"orng"}>{JSON.parse(localStorage.getItem("ActiveFFC"))}</span>
			        {
				    	(status === 'Completed')?<span className={"checktext"}>Check</span>:""
				    }
			        </li>
			      </ul>
			    </div>
			    </div>
			    <div className="menusections">
			      <div className="navitems-area" id="__navbar">
			        <ul onMouseLeave={onMouseLeave}>
			          <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem("__data"))?.DashboardMenu)?"fullarea menuisonstate ":"MenuIsoff"}>
			            <Link to="/dashboard" onClick={(e) =>{ e.preventDefault();
						(!isInEnrolled)?navigate('/dashboard'):navigate('#');}}>
			              <div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.DashboardMenu)?<img alt="MenuIsOn" className="menuison" src={MenuIsOn} />:
			                  <img alt="MenuIsOff" className="menuisoff" src={MenuIsOff} />}
			                </div>
			              Dashboard
			              </div>
			            </Link>
			          </li>
			          <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem("__data"))?.BOimagesMenu)?"fullarea menuisonstate ":"MenuIsoff"}>
					  {(JSON.parse(localStorage.getItem("__data"))?.BOimagesMenu)?
						<NavLink  to="/boimages" onClick={(e) =>{ e.preventDefault(); BOimagesNavigate()}}>
				            <div className="menuimages">
				                <div className="menuimgbg">
								{(JSON.parse(localStorage.getItem("__data"))?.BOimagesMenu)?<img alt="MenuIsOn" className="menuison" src={BOimageIsOn} />:
				                  <img alt="MenuIsOff" className="menuisoff" src={BOimageIsOff} />}
				                </div>
				              BOimages
				            </div>
			            </NavLink>
						:
						<Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
							<div className="menuimgbg">
									{(JSON.parse(localStorage.getItem("__data"))?.BOimagesMenu)?<img alt="MenuIsOn" className="menuison" src={BOimageIsOn} />:
									<img alt="MenuIsOff" className="menuisoff" src={BOimageIsOff} />}
									</div>
								BOimages
			              </div>
			            </Link>
						}
			          </li>
			          <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem("__data"))?.FullfillmentCenterMenu)?"fullarea menuisonstate ":"MenuIsoff"}>
			            {(JSON.parse(localStorage.getItem("__data"))?.FullfillmentCenterMenu)?
			            <NavLink to="/ffcenters">
			            <Tooltip
                            title="You have one or more invitations pending. Click this button or check notifications."
                            arrow
                            placement={"top-start"}
                          >
			            	<img className="custom-faicon warning-menu" src={CautionMenu} />
			            </Tooltip>			            	
			              <div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.FullfillmentCenterMenu)?<img alt="MenuIsOn" className="menuison" src={FulfillmentOn} />:
			                  <img alt="MenuIsOff" className="menuisoff" src={FulfillmentOff} />}
			                </div>
			              Fulfillment Centers
			            </div>			              
			            </NavLink>
			            :
			            <Link to="#" onClick={(e) => e.preventDefault()}>			            	
			              <div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.FullfillmentCenterMenu)?<img alt="MenuIsOn" className="menuison" src={FulfillmentOn} />:
			                  <img alt="MenuIsOff" className="menuisoff" src={FulfillmentOff} />}
			                </div>
			              Fulfillment Centers
			            </div>			              
			            </Link>
			        	}
			          </li>
			          <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem("__data"))?.StoresMenu)?"fullarea menuisonstate ":"MenuIsoff"}>
					  {(JSON.parse(localStorage.getItem("__data"))?.StoresMenu)?
						<NavLink to="/store" onClick={handleStore}>
							{(storeID)?<Tooltip
	                            title="One or more stores is missing information. Click this menu button to provide missing information and activate the store."
	                            arrow
	                            placement={"top-start"}
	                          >
								<img className="custom-faicon caution-menu" src={MenuWarning} />
							</Tooltip>:""}
							<div className="menuimages">
								<div className="menuimgbg">
								{(JSON.parse(localStorage.getItem("__data"))?.StoresMenu)?<img alt="MenuIsOn" className="menuison" src={StoresOn} />:
								<img alt="menuisoff" className="MenuIsOff" src={StoresOff} />}
								</div>
								Stores
							</div>
						</NavLink>
					  		:
					    <Link to="#" onClick={(e) => e.preventDefault()}>
			            	<div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.StoresMenu)?<img alt="MenuIsOn" className="menuison" src={StoresOn} />:
			                  <img alt="MenuIsOff" className="menuisoff" src={StoresOff} />}
			                </div>
			              	Stores
			            	</div>
			            </Link>
						}
			          </li>
			          
			          <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem("__data"))?.ProductsMenu)?"fullarea menuisonstate ":"MenuIsoff"}>
					  {(JSON.parse(localStorage.getItem("__data"))?.ProductsMenu)?
			            <NavLink to="/products" onClick={handleProductNavigation} >
			              <div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.ProductsMenu)?<img alt="MenuIsOn" className="menuison" src={ProductisOn} />:
			                  <img alt="MenuIsOff" className="menuisoff" src={ProductisOff} />}
			                </div>
			              	Products
			            	</div>
			            </NavLink>
						:
						<Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
							<div className="menuimgbg">
								{(JSON.parse(localStorage.getItem("__data"))?.ProductsMenu)?<img alt="MenuIsOn" className="menuison" src={ProductisOn} />:
								<img alt="MenuIsOff" className="menuisoff" src={ProductisOff} />}
								</div>
								Products
			              </div>
			            </Link>
						}
			          </li>
			          <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem("__data"))?.BrandsMenu)?"fullarea menuisonstate ":"MenuIsoff"}>
					  {(JSON.parse(localStorage.getItem("__data"))?.BrandsMenu)?
			            <NavLink to="/brand" onClick={(e) =>{ e.preventDefault();BrandNavigate();}}>
			              <div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.BrandsMenu)?<img alt="BrandOn"  className="menuison" src={BrandOn} />:
			                  <img alt="BrandOff"  className="menuisoff" src={BrandOff} />}
			                </div>
			                Brands
			              </div>
			            </NavLink>
						:
						<Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
							<div className="menuimgbg">
								{(JSON.parse(localStorage.getItem("__data"))?.BrandsMenu)?<img alt="MenuIsOn" className="menuison" src={BrandOn} />:
								<img alt="MenuIsOff" className="menuisoff" src={BrandOff} />}
								</div>
								Brands
			              </div>
			            </Link>
						}
			          </li>
			          <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem("__data"))?.PromotersMenu)?"fullarea menuisonstate ":"MenuIsoff"}>
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.PromotersMenu)? <img alt="BrandOn"  className="menuison" src={PromoterOn} />:
			                  <img alt="BrandOff"  className="menuisoff" src={PromoterOff} /> }
			                </div>
			                Promoters
			              </div>
			            </Link>
			          </li>
			          <li onMouseOver={onMouseOver} className={(JSON.parse(localStorage.getItem("__data"))?.AccountMenu)?"fullarea menuisonstate ":"MenuIsoff"}>
					  {(JSON.parse(localStorage.getItem("__data"))?.AccountMenu)?
						<NavLink to="/account" onClick={handleAccountMenu}>
							<div className="menuimages">
								<div className="menuimgbg">
								{(JSON.parse(localStorage.getItem("__data"))?.AccountMenu)?<img alt="menuison" className="menuison" src={AccountOn} />:
								<img alt="menuisoff" className="menuisoff" src={AccountOff} />}
								</div>
								Account
							</div>
						</NavLink>
					  		:
					   <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <div className="menuimgbg">
							{(JSON.parse(localStorage.getItem("__data"))?.AccountMenu)?<img alt="menuison" className="menuison" src={AccountOn} />:
			                  <img alt="menuisoff" className="menuisoff" src={AccountOff} />}
			                </div>
			                Account
			              </div>
			            </Link>
						  }				
			          </li>
                      
			        </ul>
			      </div>
			      <div className="blankspacearea">
			      </div>
			      <div className="navitems-area bottom-section">
			        <ul>
			        	<li className="halfarea">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <img alt="setting-on" src={SettingOn} />
              			</div>
            					Settings
            			</Link>
          			</li>
					 
			          <li className={(JSON.parse(localStorage.getItem("__data"))?.BillingMenu)?"halfarea ":"halfarea MenuIsoff"}>
					  {(JSON.parse(localStorage.getItem("__data"))?.BillingMenu)?
						<NavLink to="/billing">
							<div className="menuimages">
								{(JSON.parse(localStorage.getItem("__data"))?.BillingMenu)?<img alt="menuison" className="menuison" src={BillingOn} />:
								<img alt="menuisoff" className="menuisoff" src={BillingOff} />}
							</div>	
							Billing
							
						</NavLink>
					  		:
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <img alt="billing-on" src={BillingOff} />
			              </div>
			              Billing
			            </Link>
							}
			          </li>
			          
			          <li className="halfarea">
			            <Link to="#" onClick={(e) => e.preventDefault()}>
			              <div className="menuimages">
			                <img alt="SupportOn" src={SupportOn} />
			              </div>
			              Support
			            </Link>
			          </li>
			        </ul>
      </div> 
    </div>   
  </aside>
		</>
	);
}
export default BrandAside;