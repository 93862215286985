import React, {useState, useRef, useEffect, lazy, Suspense} from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "./assets/css/styles.css";
import "./assets/css/checkbox.css";
import axios from 'axios';
import { API_URL, API_KEY } from './config/constant'
import AccountRoute from './routes/accountRoutes'
import BrandRoute from './routes/brandRoutes'
import StoreRoute from './routes/storeRoutes'
import BillingRoute from './routes/billingRoutes'
import FFCRoute from './routes/ffcRoutes'
import ProductRoute from './routes/productRoutes'
import BoImageRoute from './routes/boImageRoutes'
import DashboardRoute from './routes/dashboardRoutes';
import PreEnrollment from './routes/preEnrollment'
import Logo from "./assets/images/logologin.png";
import FooterLogo from "./assets/images/footer-logologin.png"
import PublicRoute from './utils/publicRoute';
import BrandNavigation from "./components/layouts/DashboardHeader";
import BrandAside from "./components/layouts/DashboardAside";
import BrandFooter from "./components/layouts/DashboardFooter";
/*import Login from './components/authentication/login';*/
import PreLoader from './components/loader/preLoader';
const Login = lazy(() => import('./components/authentication/login'));
const imageData = {
  "logo": Logo,
  "footer_logo": FooterLogo
};

function App() {
  const ref = useRef();
  const [showMaster, setShowMaster] = useState(false)
  const isLoggedIn = localStorage.getItem("isLoggedIn")
  useEffect(() => {
    if(isLoggedIn){
      setShowMaster(true)
    }    
  },[isLoggedIn])
  const getUserDataFunction = (id) => {
    axios.post(API_URL+"get-user-data?code="+API_KEY,{id: id}).then(res => {
      if(res.data.status === 200){
        setShowMaster(true)
        let resData = {
          "status": res.data.data?.status,
          "completePercentage": res.data.data?.completePercentage,
          "AccountMenu": res.data.data?.AccountMenu,
          "DashboardMenu": res.data.data?.DashboardMenu,
          "BOimagesMenu": res.data.data?.BOimagesMenu,
          "FullfillmentCenterMenu": res.data.data?.FullfillmentCenterMenu,
          "StoresMenu": res.data.data?.StoresMenu,
          "RetailersMenu": res.data.data?.RetailersMenu,
          "ProductsMenu": res.data.data?.ProductsMenu,
          "BrandsMenu": res.data.data?.BrandsMenu,
          "PromotersMenu": res.data.data?.PromotersMenu,
          "companyName": (typeof res.data.data?.Companies != 'undefined')?res.data.data?.Companies[0]?.companyName:"",
          "companyID": (typeof res.data.data?.Companies != 'undefined')?res.data.data?.Companies[0]?.companyID:"",
          "companyLogo":(res.data.data?.companyDetail?.logoURL)?res.data.data?.companyDetail?.logoURL:"",
          "billingPlan":(res.data.data?.companyDetail?.billingPlan)?res.data.data?.companyDetail?.billingPlan:"",
          "BillingMenu":res.data.data?.BillingMenu
        }
        if(typeof res.data.data?.Companies != 'undefined'){
          localStorage.setItem("companyID", res.data.data?.Companies?.[0]?.companyID)
        }        
        localStorage.setItem("__data",JSON.stringify(resData));
        localStorage.setItem('name', res.data.data.firstName);
        localStorage.setItem('status', res.data.data.status);
        localStorage.setItem('isInEnrolled', res.data.data.isInEnrolled);
        localStorage.setItem("brandID",res?.data?.data?.activeBrand?.retailBrandID);
        localStorage.setItem("brandName",res?.data?.data?.activeBrand?.retailBrandName);  
        addDataIntoCache("MyCache", window.location.origin, res.data.data)
      }else{
        localStorage.clear();
      }
    })
  }
  const addDataIntoCache = (cacheName, url, response) => {
    const data = new Response(JSON.stringify(response));
    if ('caches' in window) {
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
  };
  const hideHeader = () => {
    setShowMaster(false)
  }
  const showHeader = () => {
    setShowMaster(true)
  }
  const checkUserLoggedIn = () => {
      const auth = localStorage.getItem('token');
      if(!auth){
        setShowMaster(false)
        return <Navigate to="/" />
      }
  }
  useEffect(() => {
      let interval = setInterval(checkUserLoggedIn, 1000);
      return () => clearInterval(interval);
  });

  return (
    <BrowserRouter>
      <div className="wrapper" ref={ref} id="myscreen">
      {(showMaster)?
      <>
      <BrandNavigation hideHeader={hideHeader}/>
      <BrandAside />
      </>:""} 
      <Suspense fallback={<div><PreLoader /></div>}>
        <Routes>
          <Route path="/" element={<PublicRoute><Login showHeader={showHeader} getUserDataFunction={getUserDataFunction} imageData={imageData}/></PublicRoute>} exact={true} />
          { PreEnrollment.map(({ path, Component }, index) => <Route key={index} path={path} element={<Component imageData={imageData} showHeader={showHeader}/>} exact={true}/>) }
          { AccountRoute.map((props, index) => <Route key={index} {...props} exact={true}/>) }
          { BrandRoute.map((props, index) => <Route key={index} {...props} getUserDataFunction={getUserDataFunction} exact={true}/>) }
          { StoreRoute.map((props, index) => <Route key={index} {...props} exact={true}/>) }
          { BillingRoute.map((props, index) => <Route key={index} {...props} exact={true}/>) }
          { FFCRoute.map((props, index) => <Route key={index} {...props} exact={true}/>) }
          { ProductRoute.map((props, index) => <Route key={index} {...props} exact={true}/>)}
          { BoImageRoute.map((props, index) => <Route key={index} {...props} exact={true}/>)}
          { DashboardRoute.map((props, index) => <Route key={index} {...props} exact={true}/>)}
        </Routes>
      </Suspense>
      {(showMaster)?<>
      <BrandFooter logo={imageData.footer_logo}/>
    </>:""}
    </div>
    </BrowserRouter>
    
  );
}

export default App;
