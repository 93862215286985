import Steps from './steps'
export default function First(props){
	const {name} = props
	return (
        <div className="wizardarea">
        	<div className="container-fluid">
	        	<div className="row">
	        		<div className="col-md-12">
	        			<div className="wizardtext">
			            	<h3>Hello {name}, welcome to the <span className="boldtitleinline">BO</span>stageR Platform.</h3>
			            	<h4>The BOtag technology platform for Stores.</h4>
			            	<p>You are 4 steps away from turning your stores into online fulfillment centers for the Brands you work with.</p>
			            	
			            	<div className="wizadwrap exacthieght">
			            		<div className="row">
			            			<div className="set-wizard-width">
				            			<div className="col-md-4">
				            				<div className="stepsimg">
				            					<i className="fa-light fa-user"></i>
				            					<h3>1</h3>
				            					<h4>Create an account</h4>
				            				</div>
				            			</div>
				            			<div className="col-md-4">
				            				<div className="stepsimg">
				            					<i className="fa-light fa-font-awesome"></i>
				            					<h3>2</h3>
				            					<h4>Enroll a Retail Brand</h4>
				            				</div>
				            			</div>
				            			<div className="col-md-4">
				            				<div className="stepsimg">
				            					<i className="fa-light fa-store"></i>
				            					<h3>3</h3>
				            					<h4>Enroll a Store</h4>
				            				</div>
				            				
				            			</div>
			            			</div>
			            		</div>
			            	</div>

			            	<Steps step={1} {...props} />
		            	</div>
		            </div>
	            </div>
            </div>
        </div>
    );
};