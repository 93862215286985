export const API_URL = (process.env.REACT_APP_ENV === 'prod')?'https://bostager-v2.azurewebsites.net/api/':(process.env.REACT_APP_ENV === 'test')?"https://bostager-testing.azurewebsites.net/api/":"http://localhost:7071/api/";
export const API_URL_B = (process.env.REACT_APP_ENV === 'prod')?'https://bostageb-v2.azurewebsites.net/api/':(process.env.REACT_APP_ENV === 'test')?"https://bostageb-testing.azurewebsites.net/api/":"https://bostageb-testing.azurewebsites.net/api/";
export const BLOB_URL = (process.env.REACT_APP_ENV === 'prod')?"https://bo7storage7account.blob.core.windows.net/":"https://bo7storage7account.blob.core.windows.net/";
export const BLOB_PRODUCT_CONTAINER = (process.env.REACT_APP_ENV === 'prod')?"product-images":"product-images-testing";
export const BLOB_BOIMAGE_CONTAINER = (process.env.REACT_APP_ENV === 'prod')?"bo-images":"bo-images-testing";
export const BLOB_ACCOUNT_IMAGE = (process.env.REACT_APP_ENV === 'prod')?"account-images":"account-images-testing";
export const BLOB_COMPANY_IMAGE = (process.env.REACT_APP_ENV === 'prod')?"company-images":"company-images-testing";
export const BLOB_BRAND_IMAGE = (process.env.REACT_APP_ENV === 'prod')?"brand-images":"brand-images-testing";
export const BLOB_STORE_CONTAINER =(process.env.REACT_APP_ENV === 'prod')?"store-images":"store-images-testing";
export const API_URL_A = (process.env.REACT_APP_ENV === 'prod')?'https://bostagea-v2.azurewebsites.net/api/':"https://bostagea-testing.azurewebsites.net/api/";

export const BASE_URL = (process.env.REACT_APP_ENV === 'prod')?'https://victorious-smoke-0cc7b0803.1.azurestaticapps.net':"http://localhost:3000/";
export const BASE_URL_BOTHEBOT = (process.env.REACT_APP_ENV == 'prod')?'https://zealous-pebble-0ee447b03.2.azurestaticapps.net/':"https://bothebot.progocrm.com/";
export const MAP_KEY = "AIzaSyAyGLkCMok82TO4HKWeEm-1y3tswaI8X84";     
export const API_KEY = "SQav7sgFb1xEZhDqWrYYCJZ-7l5UMHTniwY5Ul_fX-2aAzFuPTRH6Q==" 
export const API_KEY_B = (process.env.REACT_APP_ENV ==='prod')?"?code=5fH0lRGIZoSf4PRfSeBX9dEy2Q7v5JfsGxwjk1_-acqMAzFu51pV_w==":((process.env.REACT_APP_ENV ==='test')?"?code=5fH0lRGIZoSf4PRfSeBX9dEy2Q7v5JfsGxwjk1_-acqMAzFu51pV_w==":"");        
export const API_KEY_A = "4QPiN8JVsal9fyuqrTz-qM90DWvnNEMNqdy_0Ooi_WzkAzFu0eK0EQ=="



