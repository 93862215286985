import React, { useRef, useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import BrandNavigation from "./../layouts/preEnrollmentHeader";
import BrandAside from "./../layouts/preEnrollmentAside";
import BrandFooter from "./../layouts/BrandFooter";
import StepWizard from "react-step-wizard";
import transitions from './transitions.less';
import First from './firstPage'
import Second from './secondPage'
import Three from './thirdPage'
import Four from './fourPage'
import Five from './fivePage'
import Last from './lastPage'
import PreLoader from './../loader/preLoader';
import SubmitLoader from './../loader/submitLoader'
import axios from 'axios';
import {API_URL, API_KEY} from './../../config/constant'
import Warning from './../../assets/images/error-icon.png';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();
function Index(props){
	console.log("props", props);
	document.title = "BOstageR - Pre Enrollment"
	const ref = useRef();
	const uiid = useParams()
	const navigate = useNavigate();
	const [loader, setLoader] = useState(true)
	const [resData, setResData] = useState("")
	const [submitLoader, setSubmitLoader] = useState(false);
	const [companyName, setCompanyName] = useState("")
	const [email, setEmail] = useState("")
	const [userID, setUserId] = useState("")
	const [state, updateState] = useState({
        form: {},
        transitions: {
            enterRight: `${transitions.animated} ${transitions.enterRight}`,
            enterLeft: `${transitions.animated} ${transitions.enterLeft}`,
            exitRight: `${transitions.animated} ${transitions.exitRight}`,
            exitLeft: `${transitions.animated} ${transitions.exitLeft}`,
            intro: `${transitions.animated} ${transitions.intro}`,
        },
        demo: true, // uncomment to see more
    });

    useEffect(()=>{
    	console.log("Hello mai props hu",props)
    })
    const setInstance = SW => updateState({
        ...state,
        SW,
    });
    const updateForm = (key, value) => {
        const { form } = state;

        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };
    
   const addDataIntoCache = (cacheName, url, response) => {
    const data = new Response(JSON.stringify(response));
    if ('caches' in window) {
      caches.open(cacheName).then((cache) => {
        cache.put(url, data);
      });
    }
   };  

    const validateURL = (uiid) => {    	
		axios.post(API_URL+"validate-invited-url?code="+API_KEY,{uiid: uiid}).then(res => {
		//axios.post('http://localhost:7071/api/validate-invited-url',{uiid: uiid}).then(res => {
    		if(res.data.status === 200){
    			setLoader(false)
    			setResData(res.data.data)
    		} 
			else
			{
                if(res.data.status === 300){
                	let data = res.data?.data;
                	//console.log("res.data?.data",res.data?.data)
                    	localStorage.setItem('token',data?.userID)
					    localStorage.setItem('isLoggedIn',data?.isLoggedIn)
					    localStorage.setItem('email',data?.email)
					    localStorage.setItem('preEnroll',true)
					    localStorage.setItem('preEnrollingBrandName',data?.preEnrollingBrandName)
					    let resData = {
					        "status": data?.status,
					        "completePercentage": data?.completePercentage,
					        "AccountMenu": data?.AccountMenu,
					        "DashboardMenu": data?.DashboardMenu,
					        "BOimagesMenu": data?.BOimagesMenu,
					        "FullfillmentCenterMenu": data?.FullfillmentCenterMenu,
					        "StoresMenu": data?.StoresMenu,
					        "RetailersMenu": data?.RetailersMenu,
					        "ProductsMenu": data?.ProductsMenu,
					        "BrandsMenu": data?.BrandsMenu,
					        "PromotersMenu": data?.PromotersMenu,
					        "companyName": (typeof data?.Companies != 'undefined')?data?.Companies[0]?.companyName:"",
					        "companyID": (typeof data?.Companies != 'undefined')?data?.Companies[0]?.companyID:"",
					        "companyLogo":(data?.companyDetail?.logoURL)?data?.companyDetail?.logoURL:"",
					        "billingPlan":(data?.companyDetail?.billingPlan)?data?.companyDetail?.billingPlan:"",
					        "BillingMenu":data?.BillingMenu
					      }
					      if(typeof data?.Companies != 'undefined'){
					        localStorage.setItem("companyID", data?.Companies?.[0]?.companyID)
					      }        
					      localStorage.setItem("__data",JSON.stringify(resData));
					      localStorage.setItem('name', data.firstName);
					      localStorage.setItem('status', data.status);
					      localStorage.setItem('isInEnrolled', data.isInEnrolled);
					      localStorage.setItem("brandID",data.activeBrand?.retailBrandID);
					      localStorage.setItem("brandName",data.activeBrand?.retailBrandName);  
					      addDataIntoCache("MyCache", window.location.origin, data)
					      if(data?.companyDetail?.storeIDs && data?.companyDetail?.storeIDs.length){
					        localStorage.setItem("storeID",data?.companyDetail?.storeIDs[0])
					      }
					      props.showHeader()
                          navigate("/brand/creator");

                }else{
	                  toast.error(res.data.message, {
					        theme: "dark",
					        icon: ({theme, type}) =>  <img alt="icon" src={Warning}/>,
					        autoClose: 5000,
					        onOpen: () => navigate("/")
				    	})
                } 			
    		}
    	})
	}
	useEffect(() => {
		localStorage.clear();
		if(uiid?.id){
			validateURL(uiid?.id); 
		}		
	},[1])
    const handleLoader = (status) => {
    	setSubmitLoader(status)
    }
    const companyNameFun = (name) => {
    	setCompanyName(name)
    }
    const emailFun = (val) => {
    	setEmail(val)
    }
    const userIDFun = (val) => {
    	setUserId(val)
    }
	return(
		<>
			{(loader)?<PreLoader />:
			<div className="wrapper" ref={ref} id="myscreen">
				<BrandNavigation  companyName={resData?.retailer}/>
				<BrandAside />
				<div className="conten-wrapper singlepages">
					<div className="controlbar">
						<div className="productsearch leftsidetr"></div>
						<div className="centerbtn">
							<h3 className="titleonly">PRE-ENROLLMENT</h3>
						</div>
					</div>
					
					<div className="mycustom-formwizard">
						<StepWizard isHashEnabled transitions={state.transitions} instance={setInstance} >
                            <First hashKey={'FirstStep'} update={updateForm} name={resData?.firstName}/>
                            <Second form={state.form} />
                            <Three stepName='Three' />
                            <Four stepName='Four' />
                            <Five stepName='Five' loader={handleLoader} companyNameFun={companyNameFun} emailFun={emailFun} userIDFun={userIDFun} response={resData}/>
                            <Last hashKey={'TheEnd!'} loader={handleLoader} companyName={companyName} email={email} userID={userID} response={resData}  />
                        </StepWizard>
					</div>
					
				</div>
				<BrandFooter logo={props.imageData.footer_logo} />
			</div>
			}
			{(submitLoader)?<SubmitLoader />:""}
		</>

	)
}
export default Index