import {lazy} from "react";
import LoginFunc from './../utils/updateDataFunc';
import Logo from "./../assets/images/logologin.png";
import FooterLogo from "./../assets/images/footer-logologin.png";
const PrivateRoute = lazy(() => import('./../utils/privateRoute'));
const CompanyInfo = lazy(() => import('./../components/accounts/companyInfo'));
const OTPScreen = lazy(() => import('./../components/accounts/phoneVerification'));
const CompanyLogo = lazy(() => import('./../components/accounts/companyLogo'));
const CompanyList = lazy(() => import("./../components/accounts/companyList"))
/*const LoginFunc = lazy(() => import('./../utils/updateDataFunc'));*/
const imageData = {
  "logo": Logo,
  "footer_logo": FooterLogo
};

const route =  [
    { path: 'account/company/list', element: <PrivateRoute><CompanyList loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/account/company/create', element: <PrivateRoute><CompanyInfo loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/account/company/create/:company_id', element: <PrivateRoute><CompanyInfo loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: 'account/company/phone-verification', element: <PrivateRoute><OTPScreen loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/account/company/logo', element: <PrivateRoute><CompanyLogo loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> },
    { path: '/account/company/logo/:company_id', element: <PrivateRoute><CompanyLogo loginFun={LoginFunc} imageData={imageData} /></PrivateRoute> }
]
export default route;
