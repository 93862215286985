import React, { useEffect } from "react";
import { Link } from "react-router-dom";
function BrandNavigation(props) {

	const expandCollapse = (e) => {
    e.preventDefault();
		if(IsFullScreenCurrently()){
	      GoOutFullscreen();
	    }else{
	      openFullscreen();
	    }
	}
	const IsFullScreenCurrently = () => {
		
   
		
   if((document.fullScreenElement !== undefined && document.fullScreenElement === null) || (document.msFullscreenElement !== undefined && document.msFullscreenElement === null) || (document.mozFullScreen !== undefined && !document.mozFullScreen) || (document.webkitIsFullScreen !== undefined && !document.webkitIsFullScreen)){
      localStorage.setItem('fullscreen','full');
      return false;
     }else{
      localStorage.setItem('fullscreen','exit');
         return true;
     }
   
	}
	const GoOutFullscreen = () => {
		document.getElementById("expand-button").classList.remove("fa-compress");
  		document.getElementById("expand-button").classList.add("fa-expand");
  		if(document.exitFullscreen)
    		document.exitFullscreen();
  		else if(document.mozCancelFullScreen)
    		document.mozCancelFullScreen();
  		else if(document.webkitExitFullscreen)
    		document.webkitExitFullscreen();
  		else if(document.msExitFullscreen)
    		document.msExitFullscreen();
	}

	let elem = document.body;
	const openFullscreen = () => {
	  	document.getElementById("expand-button").classList.remove("fa-expand");
	  	document.getElementById("expand-button").classList.add("fa-compress");
	  	if (elem.requestFullscreen) {
	    	elem.requestFullscreen();
	  	} else if (elem.webkitRequestFullscreen) {
	    	elem.webkitRequestFullscreen();
	  	} else if (elem.msRequestFullscreen) {
	    	elem.msRequestFullscreen();
	  	}
	}

  document.addEventListener('fullscreenchange', exitHandler);
  document.addEventListener('webkitfullscreenchange', exitHandler);
  document.addEventListener('mozfullscreenchange', exitHandler);
  document.addEventListener('MSFullscreenChange', exitHandler);
  
  function exitHandler() {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
       
         document.getElementById("expand-button").classList.add("fa-expand");
        document.getElementById("expand-button").classList.remove("fa-compress");
      }
  } 


	return(
		<>
		<nav className="main-header navbar navbar-expand navbar-fixed-top">
    		<div className="previosnext">      

		
            <li className="nav-item dropdown profileopen active-brand-names">
              <Link className="nav-link" data-toggle="dropdown" to="#" onClick={(e) => e.preventDefault()} aria-expanded="true">
                <div className="profiletext">                  
              
                <span className="branddisplayname"> Enrolling Retail Brand:<span className="brandgreenname">{props.companyName} </span></span>
               
                </div>
              </Link>

             
            </li>  
			
             
          </div>
        
        
          <div className="centerbtn">
            
          </div>
       
        
          <ul className="navbar-nav ml-auto diselects">
            <li className="nav-item">
              <Link id="" className="nav-link btn btn-success" to="#" onClick={expandCollapse} role="button">
              <i className="fa-thin fa-expand" id="expand-button" aria-hidden="true"></i>
              </Link>
            </li>
            <li className="nav-item logoutbtn">
              <Link className="nav-link btn btn-success" data-widget="logout" to="#"  role="button">
              <i className="fa-thin fa-power-off" aria-hidden="true"></i>
              </Link>
            </li>
          </ul>
  		</nav>
		</>
	);
}
export default BrandNavigation;